// Created by carsonaberle at 1/17/22
// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.marketingSiteFooter {

  &--info {
    flex-direction: column;


    &-email, &-call {
      display: none;
    }

    &-links {
      width: fit-content;
      flex-direction: row;
      justify-content: space-evenly;
    }

    &-social-icon {
      width: 36px;
      height: 36px;
      cursor: pointer;
      margin: 0 12px;

      &:hover {
        opacity: 0.8;
      }
    }


    &-link {
      font-weight: 400;
      color: #f1f1f1cc;
      font-size: 14pt;
      cursor: pointer;
      margin-right: 12px;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
        opacity: 0.8;
      }
    }
  }

  &--legal {
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0 16px;

    &-text {
      font-family: BrownRegular,serif;
      color: #121212cc;
      margin-right: 24px;

      &:last-of-type {
        margin: 0;
      }

      &-name {
        display: none;
      }
    }
  }
}
