// Created by carsonaberle at 2/23/22
// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.marketingSiteServiceContent {
  &--youtube {
    flex: 1 1 100%;
    object-fit: cover;
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;
    border-radius: 24px;
    -webkit-animation: fadeIn 500ms ease-in !important;
    animation: fadeIn 500ms ease-in !important;

    & > iframe {
      width: 100% !important;
      height: 100% !important;
      border-radius: 24px;
      -webkit-animation: fadeIn 500ms ease-in !important;
      animation: fadeIn 500ms ease-in !important;
    }

    & > div {
      border-radius: 24px;
      height: fit-content;
    }
    //SHORT
    @media only screen and (max-height: 720px) {
    }

    //TABLET
    @media (min-width: 660px) and (max-width: 1024px) {
      max-height: calc(100% - 60px);
    }

    //LAPTOP
    @media only screen and (min-width: 1025px) {
      object-fit: cover;

      & > div {
        max-width: 100% !important;
      }
    }

    //MONITOR
    @media only screen and (min-height: 1500px) {
    }
  }
}
.resize-sensor {
  display: unset !important;
}
