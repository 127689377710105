// Created by carsonaberle at 2/15/22
// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.marketingServiceList {

  &__overlay {


    &-content {

      &-image {
        height: unset !important;
        flex: unset !important;
        background-color: #121212;
        margin: 0 6vw;
        border-radius: 24px !important;
        overflow: hidden;
        align-items: center;
        max-height: calc(min(88vw * 2/3, 100%)) !important;

        & > img {
          border-radius: 24px;
          overflow: hidden;
        }

        & > iframe {
          border-radius: 12px !important;
          object-fit: unset !important;
          max-width: calc(100% - 12vw);
          max-height: 240px;
        }

        &-iframe {
          max-width: calc(100% - 12vw);
          border-radius: 12px !important;
          flex: 1 !important;
          object-fit: unset !important;
        }
      }
    }
  }

  &__item {


    &-title {
      font-weight: 700;
      font-family: 'BrownRegular', serif;
    }

    &-price {
      font-weight: 300;
      font-family: 'BrownRegular', serif;
    }
  }
}
