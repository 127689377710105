// Created by carsonaberle at 2/15/22
// TABLET STYLES BELOW *****************************************************************************************
@media (min-width: 660px) and (max-width: 1024px) {
  .marketingServiceList {

    &__item {

    }

    &__overlay {
      &-content {

        &-image {
          margin: 0;

          &-iframe {
          }

          & > iframe {
            max-height: 600px;
          }
        }
      }
    }
  }
}
