// Created by carsonaberle at 1/17/22
// DESKTOP STYLES BELOW ****************************************************************************************
@media only screen and (min-width: 1025px) {
  .marketingSiteFooter {

    &--info {
      flex-direction: row;

      &-call {
        display: flex;
      }
    }
  }
}

@media only screen and (min-width: 1500px) {
  .marketingSiteFooter {

    &--info {

      &-email, &-call {
        display: flex;
      }
    }
  }
}
