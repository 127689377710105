// Created by carsonaberle at 2/23/22
// TABLET STYLES BELOW *****************************************************************************************
@media (min-width: 660px) and (max-width: 1024px) {
  .marketingSitePortal {


    &--header {

      &-title {
        width: 100%;
        max-width: 100%;
      }
    }

  }
}
