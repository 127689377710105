// Created by carsonaberle at 2/23/22
// DESKTOP STYLES BELOW ****************************************************************************************
@media only screen and (min-width: 1025px) {
  .marketingSitePortal {

    &--header {

      &-container {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      &-title {
        font-size: 32pt;
      }
    }

  }
}
