// Created by carsonaberle at 2/15/22
// DESKTOP STYLES BELOW ****************************************************************************************
@media only screen and (min-width: 1025px) {
  .marketingServiceList {

    &__overlay {
      &_content {
        &-image {
          margin: 0;
        }
      }
    }

    &__item {

      &-selected {
        border-width: 3px;
        padding: 0 23px;
      }
    }
  }
}
