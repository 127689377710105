// Created by carsonaberle at 3/3/22
// MOBILE AND DEFAULT STYLES BELOW *****************************************************************************************
.marketingSiteReviews {
  background-color: #121212;
  padding: 90px 6vw;

  &__header {

    &-title {
      font-family: 'BrownRegular', sans-serif;
      font-weight: 600;
      font-size: 30pt;
    }

    &-description {
      font-family: 'BrownRegular', sans-serif;
      font-weight: 600;
      font-size: 14pt;
      margin-top: 12px;
      max-width: 720px;
    }
  }

  &__reviews {
    flex-direction: row;
    margin-top: 60px;
    overflow-x: scroll;
    overflow-y: hidden;
    justify-content: flex-start;

    &--item {
      position: relative;
      flex: 1;
      margin: 0 24px 0 0;

      &:last-of-type {
        margin-right:0;
      }


      &-open-quote {
        position: absolute;
        top: -24px;
        left: -36px;
        color: #f1f1f1;
        font-size: 48pt;
        font-weight: 700;
      }

      &-close-quote {
        position: absolute;
        bottom: -64px;
        right: -36px;
        color: #f1f1f1;
        font-size: 48pt;
        font-weight: 700;
      }

      &-agent {


        &-image {

        }

        &-name {

        }

        &-agency {
          margin-top: 3px;
          font-size: 16pt;
          font-family: BrownRegular, sans-serif;
        }
      }
    }
  }
}
