// Created by carsonaberle at 1/17/22
// TABLET STYLES BELOW *****************************************************************************************
@media (min-width: 660px) and (max-width: 1024px) {
  .marketingSiteFooter {


    &--info {
      padding: 6vw;
      flex-direction: row;

      &-email, &-call {
        display: none;
      }
    }
  }
}

@media (min-width: 660px) and (max-width: 600px) {
  .marketingSiteFooter {
    &--info {

      &-facebook {
        display: none;
      }
    }

    &--legal {
      padding: 0 6vw;
      &-text {
        color: #121212cc;
        &-name {
          display: none;
        }
      }
    }
  }
}
