// Created by carsonaberle at 3/3/22
// TABLET STYLES BELOW *****************************************************************************************
@media (min-width: 660px) and (max-width: 1024px) {
  .marketingSiteReviews {

    &__reviews {
      &--item {
        width: 50%;
      }
    }
  }
}
